(function ($) {

    /**
     * initializeBlock
     *
     * Adds custom JavaScript to the blockname-block HTML.
     *
     * @date    15/4/19
     * @since   1.0.0
     *
     * @return  void
     */
    var initializeBlock = function ($block) {
        Fancybox.bind('[data-fancybox]', {
            type: "inline",
            defaults: {
                hideScrollbar: 0
            },
            Html: {
                vimeo: {
                    byline: 1,
                    controls: 1,
                    dnt: 1,
                    muted: 0,
                }
            }
        });
    }

    // Initialize each block on page load (front end).
    $(document).ready(function () {
        initializeBlock();
    });

    // Initialize dynamic block preview (editor).
    if (window.acf) {
        window.acf.addAction('render_block_preview/type=hero-block', initializeBlock);
    }

})(jQuery);
